(function ($, Drupal) {
  'use strict';

  /**
   * Main Menu Formatter UK v1
   */
  var mainMenuFormatterUKV1 = {
    formatter: $('#site-navigation')
  };

  /* Keyboard Events for Menu Dropdown and Carousel - Start*/
  $('.js-is_dropdown').on('focusin', function () {
    $(this).trigger('mouseenter');
  });

  $('.js-is_carousel').on('focusin', function () {
    var $thisCarousel = $(this).parents('.is-carousel');
    var $lastChild = $thisCarousel.children(':last');

    $lastChild.find('.js-carousel_controls').on('focusout', function () {
      $thisCarousel.trigger('mouseleave');
    });
  });
  /* Keyboard Events for Menu Dropdown and Carousel - End*/

  /* Keyboard Events for SubMenus - Start*/
  $('.js-mm_subnav_item').on('focusin', function () {
    var $this = $(this);

    $this.siblings().removeClass('is-active');
    if ($this.hasClass('is-submenu')) {
      $this.addClass('is-active');
      $this.find('.js-subMenu-link').attr('aria-expanded', 'true');
    }
    if ($this.next().length === 0) {
      $this.on('focusout', function () {
        var $mainMenu = $this.parents('.js-nav_dropdown');

        $mainMenu.attr('aria-expanded', 'false').trigger('mouseleave');
        $mainMenu.next(':focusable').focus();
      });
    }
  });

  $('.js-mm_subnav_item').each(function () {
    if ($(this).hasClass('is-submenu')) {
      $(this).find('.js-subMenu-link').attr({
        'aria-expanded': 'false',
        'aria-haspopup': 'true'
      });
    }
  });
  $('.js-my_mac_link').focus(function () {
    $(this).parent().trigger('mouseenter');
    $(this).find('.js-my-mac-overlay').focus();
  });

  /* Keyboard Events for SubMenus - End*/

  Drupal.behaviors.mainMenuFormatterUKV1 = {
    get: function (key) {
      return mainMenuFormatterUKV1[key];
    },

    set: function (key, val) {
      mainMenuFormatterUKV1[key] = val;
    },

    buildMobileNav: function ($module, context) {
      var $mobileNav = $('#mobile-navigation');

      if (!$mobileNav.hasClass('mobileNav-processed')) {
        $mobileNav.after('<div class="mobile-nav__overlay"></div>');
        var mobileNavOverlay = $('.mobile-nav__overlay');

        // add menu-sub attribute and format menu link text.
        $mobileNav.find('.field-menu').each(function () {
          $(this).find('.menu').first().attr('menu-sub', '0');
          $(this).find('.menu-item-container').each(function (i) {
            $(this).attr('menu-sub', i + 1);
            var text = $(this).contents().filter(function () {
              return this.nodeType === 3;
            });

            text.wrap('<div class="menu--title"></div>');
          });
        });

        // find page & parent cat to contextualize nav state
        var currentPage;
        var ancestorDepthIndices = []; // "menu-sub" item value

        if (page_data && page_data['consolidated-categories'] && page_data['consolidated-categories'].categories) {
          var categories = page_data['consolidated-categories'].categories;

          if (categories.length >= 1) {
            currentPage = {
              url: categories[0].url,
              catId: categories[0].BREADCRUMB_MENU_CAT_ID ? 'CAT' + categories[0].BREADCRUMB_MENU_CAT_ID : categories[0].CATEGORY_ID,
              parentCatId: categories[0].PARENT_CAT_ID,
              breadcrumbCatId: categories[0].BREADCRUMB_MENU_CAT_ID ? categories[0].BREADCRUMB_MENU_CAT_ID : categories[0].PARENT_CAT_ID
            };
          }
        }
        if (currentPage && currentPage.catId) {
          var catid = currentPage.catId.split('CAT');

          catid = catid[1];
          // category link that matches current page
          $mobileNav.find('.field-menu').each(function () {
            var activeTrailNodes = $(this).find("a[href*='/products/" + catid + "/']");

            activeTrailNodes.each(function (i) {
              var ancestorMenuItems;

              // first instance of the active menu: contains the depth/tree of all ancestor menus
              if (i == 0) {
                ancestorMenuItems = $(this).parentsUntil($('div.field-menu'), 'li.menu-item-container');
                ancestorMenuItems = $(this).closest('li.menu-item-container');
                if (typeof ancestorMenuItems[0] != 'undefined') {
                  ancestorMenuItems[0].className += ' js-mobile-nav-submenu-' + currentPage.breadcrumbCatId;
                }
                ancestorMenuItems.each(function (i) {
                  ancestorDepthIndices.push($(this).attr('menu-sub')); // stores indices of ancestor items in nav heirarchy
                });
              }
            });
          });

          // secondary nav link (mobile breadcrumb on product pages)
          $('#js-sec-nav-link-' + currentPage.breadcrumbCatId).click(function (e) {
            e.preventDefault();
            if (!$('#site-header .site-header__extra #mobile-nav').is(':visible')) {
              return;
            }
            var parentCatId = $(this).attr('data-catid');

            if (!parentCatId || parentCatId === '') {
              return;
            }
            $.sidr('open', 'mobile-navigation');
            var submenu = $('.js-mobile-nav-submenu-' + parentCatId + ':visible');

            if (submenu.length) {
              $('#mobile-navigation ul.menu[menu-sub]').each(function () {
                $(this).hide();
              });
              submenu.addClass('external-trigger');
              submenu.trigger('click');
            }
          });
        }

        var headerHeight = $('.site-header__fixed-wrapper').height();
        var bodyPadding = $('body').css('padding-top');
        var mobileNavToggle = $('#site-header .site-header__extra #mobile-nav');
        var dimOverlay = $('<div class="site-header__dim-overlay"></div>');
        var siteContainer = $('.site-header').siblings('.site-container');
        var bannerHeight;
        var topOffset;
        var navSide = site.direction.isRTL ? 'right' : 'left';
        var externalTrigger;

        mobileNavToggle.sidr({
          displace: false,
          name: 'mobile-navigation',
          renaming: false,
          source: '#mobile-navigation',
          side: navSide,

          onOpen: function () {
            $('body, html').addClass('noscroll fixedpos sidr-open');
            $('.site-header__fixed-wrapper').addClass('header-pos');
            bodyPadding = $('body').css('padding-top');
            dimOverlay.prependTo(siteContainer);

            if ($('.header-search__icon').hasClass('search-active')) {
              $('.header-search__icon').trigger('click');
            }
            bannerHeight = $('html').hasClass('site-banner__is-open') ? $('.site-banner').height() : 0;
            topOffset = headerHeight + bannerHeight;
            $mobileNav.css({
              'top': topOffset + 'px',
              'height': 'calc(100% - ' + topOffset + 'px)',
              'margin-top': bodyPadding
            });

            // listen for banner being closed
            $(window).on('sidewideBanner:up', function () {
              // recalculate bannerheight with sitewideBanner closed
              bannerHeight = $('html').hasClass('site-banner__is-open') ? $('.site-banner').height() : 0;
              topOffset = headerHeight + bannerHeight;
              $mobileNav.css({
                'top': topOffset + 'px',
                'height': 'calc(100% - ' + topOffset + 'px)',
                'margin-top': bodyPadding
              });
            });

            var mainMenu = $mobileNav.find('.menu[menu-sub="0"]:visible');
            var scrollableCont = $mobileNav;
            var fieldMenu = scrollableCont.find('.field-menu:visible');
            var prevMenu = [];
            var scrollTopPos = 0;

            mainMenu.once().addClass('is-current'); // default to top level
            // scrollableCont[0].scrollTop = scrollTopPos;

            $mobileNav.find('.menu-item-container').once().click(function (e) {
              var parentMenu = $(this).parents('.menu-item-container');
              var item = $(this).attr('menu-sub');
              var last = prevMenu[prevMenu.length - 1];
              var $thisMenu = $(this);
              var isWhatsNew = $thisMenu.hasClass('mm-whats-new-items-mobile-menu');
              var $newItemsMenu = $mobileNav.find('.mm-whats-new-items-mobile-menu');

              externalTrigger = false;

              // external trigger (e.g. secondary nav): simulate the depth/history
              if (currentPage && currentPage.parentCatId) {
                if ($(e.target).hasClass('external-trigger')) {
                  $(e.target).removeClass('external-trigger');
                  externalTrigger = true;
                }
              }

              // different animation type and speed depending on the menu (WHATS NEW is very tall)
              var animationType = isWhatsNew ? 'easeInOutQuad' : 'easeInQuart';// (isWhatsNew)? 'easeInOutQuad': 'easeOutExpo';
              var animationSpeed = isWhatsNew ? 900 : 300;
              var slideUpCompleted = false;
              var slideDownCompleted = false;

              if (!$(e.target).is('a')) {
                // selected sub-menu is not active so expand it
                if (!$thisMenu.hasClass('is-active')) {
                  // close all expanded menus except parent containers
                  if (externalTrigger) {
                    $thisMenu.add('.menu-item-container').not(parentMenu).removeClass('is-current is-active').find('.menu-container').hide();
                  } else {
                    $thisMenu.add('.menu-item-container').not(parentMenu).removeClass('is-current is-active').find('.menu-container').slideUp(animationSpeed, animationType);
                  }

                  $thisMenu.addClass('is-active is-current');

                  // if externaltrigger, show target depth & ancestors
                  if (externalTrigger) {
                    mainMenu.show();
                    $thisMenu.find('.menu-container').first().css('display', 'block');
                    $thisMenu.addClass('is-active');
                    prevMenu = ancestorDepthIndices.slice(0);
                    prevMenu.reverse();
                    prevMenu.pop();
                  } else {
                    // identify current menu and expand
                    $thisMenu.find('.menu-container').first().slideDown(animationSpeed, animationType);
                  }

                  // add height to ensure container is scrollable
                  fieldMenu.css('height', '100%');
                  if (isWhatsNew) {
                    fieldMenu.css('height', $newItemsMenu.height() * 2.5);
                  } else {
                    fieldMenu.css('height', $mobileNav.height() * 2.5);
                  }
                  // animate once animation completed
                  $mobileNav.find('.menu-container').promise().done(function () {
                    // get current menu top position
                    if (isWhatsNew) {
                      scrollTopPos = 0;
                    } else {
                      scrollTopPos = $('.menu-item-container[menu-sub="' + item + '"] .menu--title:visible').position().top;
                    }
                    // position scrollable area
                    if (externalTrigger) {
                      scrollableCont[0].scrollTop = scrollTopPos;
                    } else {
                      scrollableCont.animate({
                        scrollTop: scrollTopPos
                      }, 300);
                    }
                  });

                  parentMenu.add(mainMenu).removeClass('is-current');
                  // if we are at the top level
                  if (parentMenu.length === 0) {
                    prevMenu = [];
                    last = 0;
                  } else if (!externalTrigger) {
                    prevMenu.push(parentMenu.attr('menu-sub'));
                  }

                // selected sub-menu is active, but menu is hidden
                } else if (externalTrigger) {
                  mainMenu.show();
                  scrollableCont[0].scrollTop = scrollTopPos;

                // selected sub-menu is already active & menu is visible
                } else {
                  // close all expanded menus except parent containers
                  $thisMenu.add('.menu-item-container').not(parentMenu).removeClass('is-current is-active').find('.menu-container').slideUp(animationSpeed, animationType);

                  // if we are at the top level
                  if (parentMenu.length === 0) {
                    prevMenu = [];
                    last = 0;
                  }

                  if (last > 0) {
                    fieldMenu.css('height', '120%');
                    $mobileNav.find('.menu-item-container[menu-sub="' + last + '"]').addClass('is-active is-current');
                    // add height to ensure container is scrollable
                    fieldMenu.css('height', $mobileNav.height() * 2.5);
                    scrollableCont.animate({
                      scrollTop: $('.menu-item-container[menu-sub="' + last + '"] .menu--title').position().top
                    }, 300);

                    prevMenu.pop();
                  } else {
                    $mobileNav.find('.menu-item-container').removeClass('is-current is-active');
                    mainMenu.addClass('is-current is-active');
                    fieldMenu.css('height', '100%');
                  }
                }

                return false;
              }
            });

            mobileNavOverlay.once().click(function () {
              $.sidr('close', 'mobile-navigation');

              return false;
            });

            // $(window).resize(function() {
            // $.sidr('close', 'mobile-navigation');
            // });
            $(window).on('orientationchange', function () {
              $.sidr('close', 'mobile-navigation');
            });
          },
          onClose: function () {
            $('body, html').removeClass('noscroll fixedpos sidr-open');
            $('.site-header__fixed-wrapper').removeClass('header-pos');
            dimOverlay.remove();
            // hack for resetting body padding from admin toolbar
            if ($.cookie('client.isMobile') === 0 && bodyPadding !== '0px') {
              setTimeout(function () {
                $('body').css('padding-top', bodyPadding);
              }, 300);
            }
          }
        });

        // rewrite What's New menu with content blocks from items node: node/1968
        var $whatsNewMenuContent = $mobileNav.find('.site-navigation > .block-nodeblock');
        var $whatsNewArray = [
          {
            menuName: "What's New", // just for human readability
            menuSubID: 1
          }
        ];

        $($whatsNewArray).each(function (index) {
          var $whatsNewMenu = $mobileNav.find('.menu-item-container[menu-sub="' + $whatsNewArray[index].menuSubID + '"]');
          // var $whatsNewItems = $mobileNav.find('.mm-whats-new-item-mobile')[0].parents('.content');
          var $whatsNewItems = $($whatsNewMenuContent[index + 1]).find('.content').first(); // i+1 is to skip main menu

          if ($whatsNewItems.length > 0) {
            $whatsNewItems.addClass('mm-whats-new-items-mobile-contents');
            $whatsNewMenu.addClass('mm-whats-new-items-mobile-menu');
            $whatsNewMenu.find('.menu-container').empty().append($whatsNewItems);
            $whatsNewMenu.find('.mm-whats-new-items-mobile-contents').wrap('<ul class="menu"><li class="menu-item-container" menu-sub="999"></li></ul>');
          }
        });
      }
      $mobileNav.addClass('mobileNav-processed');
    },

    buildMoreMenu: function ($module, menu, moreMenu, moreMenuMenu) {
      // Start by resetting everything
      moreMenuMenu.empty();
      var menuItems = $('li.site-navigation__item:not(.subnav):not(.site-navigation__more)', menu);

      // Determine how much width we have
      moreMenu.show();
      var widthMoreMenu = parseInt(moreMenu.outerWidth(true));

      moreMenu.hide();
      var availableWidth = parseInt(menu.outerWidth(true)) - widthMoreMenu;
      var menuWidthSoFar = 0;

      // For each menu element
      menuItems.each(function () {
        var item = $(this);

        // Calculate the width of the menu so far
        item.show();

        // For the last element, use only the inner width
        if (item[0] == menuItems.last()[0]) {
          menuWidthSoFar += item.width();
        } else {
          menuWidthSoFar += item.outerWidth(true);
        }

        // If the menu overflows, move the item to the More link
        if (menuWidthSoFar > availableWidth && !item.hasClass('site-navigation__more')) {
          item.clone(true).css('display', 'block').appendTo(moreMenuMenu);
          item.hide();
        }
      });

      if ($.trim(moreMenuMenu.html()).length) {
        moreMenu.show();
      } else {
        moreMenu.hide();
      }
    },

    initDropdowns: function ($module) {
      $module.find('.is-dropdown').not('.site-navigation__more').each(function (i) {
        $(this).attr('menu-sub', i);
        var dropdownMenuItem = $(this).find('ul.site-navigation__menu');

        if (!dropdownMenuItem.parents('.site-navigation__dropdown').length) {
          dropdownMenuItem.wrap('<div class="site-navigation__dropdown js-nav_dropdown" menu-sub="' + i + '"><div class="responsive-container"></div></div>');
        }
      }).promise().done(function () {
        $(window).trigger('gnav::initDropdowns');
      });
      var carousel = $module.find('.slick-initialized');

      // console.log(carousel);
      $module.find('.is-dropdown').once().on('mouseenter', function () {
        $(document).trigger('click');
        var thisDropdown = $(this);

        thisDropdown.find('.js-mainMenu_link').attr('aria-expanded', 'true');
        if (thisDropdown.find('a[href*="omit_link"]')) {
          thisDropdown.find('a[href*="omit_link"]').attr('href', '').click(false);
        }

        var $dropdown = thisDropdown.find('.site-navigation__dropdown');

        if (thisDropdown.hasClass('is-carousel')) {
          $dropdown = thisDropdown.find('.site-navigation__carousel');
        }
        if (thisDropdown.length == 1 && !thisDropdown.hasClass('.site-navigation__more') && $dropdown.length == 1) {
          $(window).scroll();
          if (!thisDropdown.hasClass('is-active')) {
            thisDropdown.addClass('is-active');
            $dropdown.slideDown(0, function () {
              $(this).css('overflow', 'visible');
              if (thisDropdown.hasClass('is-carousel')) {
                carousel.slickSetOption('infinite', false, true);
              }
            }).bind('mouseleave', function (e) {
              $dropdown.find('.site-navigation__item').removeClass('is-active');
              thisDropdown.removeClass('is-active');
              if (!Drupal.settings.globals_variables.gnav_show) {
                $(this).hide();
              }
              thisDropdown.find('.js-mainMenu_link').attr('aria-expanded', 'false');
            });
          }
          $(window).scroll(function () {
            if (thisDropdown.hasClass('is-active')) {
              $dropdown.find('.site-navigation__item').removeClass('is-active');
              $dropdown.hide();
              thisDropdown.removeClass('is-active');
            }
          });
          $(window).one('resize', function () {
            if (thisDropdown.hasClass('is-active')) {
              $dropdown.slideUp(300, function () {
                $dropdown.find('.site-navigation__item').removeClass('is-active');
                thisDropdown.removeClass('is-active');
              });
            }
          });

          var $blockimageFocus = $('.block-image-hover');

          $blockimageFocus.each(function () {
            $blockimageFocus.on('focusin', function () {
              $(this).addClass('focused');
            }).on('keydown', function (e) {
              if (e.shiftKey) {
                $blockimageFocus.removeClass('focused');
              }
            });
            $blockimageFocus.find('[role]').on('focusout', function () {
              $blockimageFocus.removeClass('focused');
            });
          });

          return false;
        }
      }).on('mouseleave', function () {
        $('.site-navigation__item').removeClass('is-active');
        $('.site-navigation__dropdown').hide();
        $('.site-navigation__carousel').hide();
      });
      $module.find('li.site-navigation__item:not(.subnav):not(.site-navigation__more)').not('.is-dropdown').hover(function () {
        $('.site-navigation__item').removeClass('is-active');
        $('.site-navigation__dropdown').hide();
      });
      $module.find('.site-navigation__link').click(function () {
        var href = $(this).attr('href');

        if (href == '' || href == '/') {
          return false;
        }
      });

      $module.find('.is-submenu').hover(function () {
        $module.find('.is-submenu').removeClass('is-active');
        $(this).addClass('is-active');
        $(this).find('.js-subMenu-link').attr('aria-expanded', 'true');
      }, function () {
        $(this).removeClass('is-active');
        $(this).find('.js-subMenu-link').attr('aria-expanded', 'false');
      });
    },

    attach: function (context, settings) {
      var self = this;
      var formatter = $(self.get('formatter'), context);

      formatter.each(function () {
        var thisFormatter = $(this);
        var $module = thisFormatter;
        // mobile nav
        var $mobileNav = thisFormatter.find('#mobile-nav', context);
        var $mobileBackButton = $('.mobile-checkout-back', context).first();

        if ($('body.index, body.samples, body.wp_samples').length) {
          if (!$('#confirmation-page').length) {
            if (!$('.site-header__extra .mobile-checkout-back', context).length) {
              $mobileBackButton.appendTo('#site-header .site-header__extra');
            }
            $mobileBackButton.removeClass('hidden');
            $mobileNav.remove();
            $mobileBackButton.once().click(function () {
              window.history.back();
            });
          } else if ($('#confirmation-page').length) {
            $mobileBackButton.remove();
          }
        } else {
          if (!$('.site-header__extra #mobile-nav', context).length) {
            $mobileNav.appendTo('#site-header .site-header__extra');
          }
          $mobileNav.removeClass('hidden');
          $mobileBackButton.remove();
        }

        // drop-down menus
        self.initDropdowns($module, context);
        // mobile nav
        self.buildMobileNav($module, context);

        // More menu

        var menu = $('ul.site-navigation__menu', $module).first();
        var $subMenu = $('.mm-item-v1', menu);
        var $subCarousel = $('.site-navigation__carousel', $subMenu);
        var moreText = menu.attr('data-more-text');
        // console.log(moreText);
        var moreMenu = $('<li class="site-navigation__item site-navigation__more"><a href="#">' + moreText + ' <i class="icon--caret-down"></i></a></li>').appendTo(menu);
        var moreMenuMenu = $('<ul class="site-navigation__more-menu"></ul>').appendTo(moreMenu);
        var throttle;

        // console.log($module, menu, moreMenu, moreMenuMenu);
        // Using $(window).load() due to a known caching issue in Chrome which prevents the code from making accurate calculations
        $(window).load(function () {
          $(window).resize(_.debounce(function () {
            self.buildMoreMenu($module, menu, moreMenu, moreMenuMenu);
          }, 50)).resize();

          // Adds tabindex for content in MAC Select FAQ
          $('.mac-faq__q').attr({
            'tabindex': '0',
            'aria-expanded': 'false'
          });
        });
        moreMenu.find('> a').once().click(function (e) {
          if (!moreMenu.hasClass('active')) {
            moreMenu.addClass('active');
            menu.css('overflow', 'visible');
            moreMenuMenu.slideDown(300);

            return false;
          } else {
            moreMenu.removeClass('active');
            moreMenuMenu.slideUp(300);
          }
        });
        $('html, body').click(function () {
          moreMenu.removeClass('active');
          moreMenuMenu.slideUp(300);
        });
        moreMenuMenu.on('mouseleave', function () {
          $(this).stop().slideUp(300);
        });
      });
    }
  };
})(jQuery, Drupal);
